<template>
  <div class="root">
    <div class="title">
      <div class="title_image">
        <img src="@/assets/success.png" />
      </div>
      <div class="title_content">
        <div class="title_content_row1">感谢您，购买成功！</div>
        <div class="title_content_row2">
          共计支付<span class="title_content_row2_price"
            >¥{{ paymentMoney }}</span
          >
        </div>
      </div>
    </div>
    <div class="body">
      <div class="divTable">
        <div class="divTableBody">
          <div class="divTableRow">
            <div class="divTableCell"><b>我的订单详情</b></div>
          </div>
          <div class="divTableRow">
            <div class="divTableCell">订单编号：{{ orderSn }}</div>
            <div class="divTableCell">下单时间：{{ createTime }}</div>
          </div>
          <div class="divTableRow">
            <div class="divTableCell">支付单号：{{ orderPaymentNumber }}</div>
            <div class="divTableCell">支付时间：{{ payTime }}</div>
          </div>
          <div class="divTableRow">
            <div class="divTableCell">支付方式：{{ paymentMethod }}</div>
            <div class="divTableCell">商品名称：{{ productName }}</div>
          </div>
          <div class="divTableRow">
            <div class="divTableCell">购买数量：{{ productCnt }}</div>
            <div class="divTableCell">
              实付金额：<b>¥{{ paymentMoney }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <el-button type="success" @click="goBuy">继续购买</el-button>
    </div>
  </div>
</template>

<script>
export default {
  created: function () {
    let query = JSON.parse(this.$route.query.jsonData);
    this.createTime = query.createTime;
    this.orderSn = query.orderSn;
    this.payTime = query.payTime;
    this.orderPaymentNumber = query.orderPaymentNumber;
    this.productName = query.drpOrderDetail.productName;
    this.productCnt = query.drpOrderDetail.productCnt;
    this.paymentMoney = query.paymentMoney;
    // this.loadData();
  },
  data: function () {
    return {
      createTime: "",
      orderSn: "",
      paymentMethod: "微信支付",
      payTime: "",
      orderPaymentNumber: "",
      productName: "",
      productCnt: "",
      paymentMoney: "",
    };
  },
  methods: {
    loadData: function () {
      this.$post(
        "/order/drp-order-main/detail",
        { orderSn: "575025255702388736" },
        3000,
        true,
        2
      ).then((res) => {
        this.createTime = res.data.createTime;
        this.orderSn = res.data.orderSn;
        this.payTime = res.data.payTime;
        this.orderPaymentNumber = res.data.orderPaymentNumber;
        this.productName = res.data.drpOrderDetail.productName;
        this.productCnt = res.data.drpOrderDetail.productCnt;
        this.paymentMoney = res.data.paymentMoney;
      });
    },
    goBuy: function () {
      this.$router.push({
        path: "/web/jgDistribution/distributionShoppingMall",
      });
    },
  },
};
</script>

<style>
.title {
  margin: 100px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.title_image img {
  width: 70px;
  height: auto;
}
.title_content {
  margin-left: 20px;
}
.title_content_row1 {
  color: #b45f06;
  font-size: 24px !important;
  font-weight: bold;
}
.title_content_row2 {
  margin-top: 10px;
  font-size: 14px !important;
}
.title_content_row2_price {
  margin-left: 1ex;
  color: #b45f06;
  font-size: 18px !important;
}
.body {
  margin: 0 50px;
  border: 1px black solid;
}
/* DivTable.com */
.divTable {
  display: table;
  width: 100%;
  margin: 20px;
}
.divTableBody {
  display: table-row-group;
}
.divTableRow {
  display: table-row;
}
.divTableCell {
  display: table-cell;
  padding: 5px;
}
.foot {
  margin-top: 20px;
  text-align: center;
}
</style>